<template>
    <div :class="mode === 'print' ? 'order-print' : 'order-detail'" v-if="content">
        <div class="header">
            <div class="yellow">
                <div class="name">
                    บริษัท เยลโลไทร์ จำกัด
                    <br />
                    Yellowtire Co., Ltd.
                </div>
                <address>
                    เลขที่ 1818 อาคาร รสา ทู ชั้น 18 ถนนเพชรบุรี 
                    <br />
                    แขวงมักกะสัน เขตราชเทวี กรุงเทพมหานคร 10400
                    <br />
                    เลขประจำตัวผู้เสียภาษี 0105564107895
                </address>
            </div>
        </div>

        <div class="title mt-3">
            <div class="data">
                <h2>หมายเลขคำสั่งซื้อ</h2>
                <span>{{ content.orderNumber }}</span>
            </div>
            <div class="data text-end">
                <span>เลขที่ {{ content.receiptNumber }}</span>
                <span>วันที่ทำรายการ {{ content.orderDate }}</span>
            </div>
        </div>
 
        <template v-if="content.orderType === constants.ORDER_TYPE.SERVICE">
            <div class="info">
                <div class="left">
                    <h3>ข้อมูลผู้ซื้อ</h3>
                    <div class="data">
                        {{ content.customer.firstName }} {{ content.customer.lastName }}
                        <br />
                        {{ content.customer.tel }}
                        <br />
                        {{ content.customer.licenseNumber }} {{ content.customer.licenseProvince }}
                        <br />
                        {{ content.customer.carBrand }} {{ content.customer.carModel }}
                    </div>

                    <template v-if="content.customer.requireTax">
                        <h3>ข้อมูลใบกำกับภาษี</h3>
                        <div class="data">
                            <template v-if="content.customer.requireElecTax">
                                ** ต้องการใบกำกับภาษีแบบอิเล็กทรอนิกส์ (e-Tax) 
                                <br />
                                ** ส่งที่: <i>{{ content.customer.taxEmail }}</i>
                                <br />
                            </template>
                            {{ content.customer.taxName }}
                            <br />
                            ที่อยู่ {{ content.customer.taxAddress }} {{ content.customer.taxSubDistrict }} {{ content.customer.taxDistrict }} {{ content.customer.taxProvince }} {{ content.customer.taxPostCode }}
                            <br />
                        </div>
                    </template>

                    <h3>วิธีชำระเงิน</h3>
                    <div class="data">
                        {{ content.paymentMethod }}
                    </div>
                </div>
                <div class="right">
                    <h3>ร้านค้า</h3>
                    <div class="data">
                        {{ content.storeData.name }}
                        <br />
                        {{ content.storeData.address }}
                        <br />
                        เบอร์ติดต่อ: {{ content.storeData.tel }}
                        <br />
                        เลขประจำตัวผู้เสียภาษี: {{ content.storeData.taxId }}
                    </div>

                    <h3>วันเวลานัดหมาย</h3>
                    <div class="data">
                        {{ content.appointmentDateText }}
                        <br />
                        {{ content.appointmentTime }}
                    </div>
                </div>
            </div>
        </template>

        <template v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
            <div class="info">
                <div class="left">
                    <h3>ผู้ส่ง (From)</h3>
                    <div class="data">
                        {{ content.storeData.name }}
                        <br />
                        {{ content.storeData.address }}
                        <br />
                        เบอร์ติดต่อ: {{ content.storeData.tel }}
                        <br />
                        เลขประจำตัวผู้เสียภาษี: {{ content.storeData.taxId }}
                    </div>

                    <h3>ผู้รับ (To)</h3>
                    <div class="data">
                        ส่งถึง: {{ content.shippingData.receiverName }}
                        <br />
                        เบอร์ติดต่อ: {{ content.shippingData.receiverTel }}
                        <br />
                        ที่อยู่จัดส่ง: {{ content.shippingData.receiverAddress }}
                        <template v-if="content.shippingData.deliveryRemark">
                            <br />
                            หมายเหตุ: {{ content.shippingData.deliveryRemark }}
                        </template>
                    </div>

                    <template v-if="content.customer.requireTax">
                        <h3>ข้อมูลใบกำกับภาษี</h3>
                        <div class="data">
                            <template v-if="content.customer.requireElecTax">
                                ** ต้องการใบกำกับภาษีแบบอิเล็กทรอนิกส์ (e-Tax)
                                <br />
                                ** ส่งที่: <i>{{ content.customer.taxEmail }}</i>
                                <br />
                            </template>
                            {{ content.customer.taxName }}
                            <br />
                            ที่อยู่ {{ content.customer.taxAddress }} {{ content.customer.taxSubDistrict }} {{ content.customer.taxDistrict }} {{ content.customer.taxProvince }} {{ content.customer.taxPostCode }}
                            <br />
                            เลขประจำตัวผู้เสียภาษี {{ content.customer.taxId }}
                        </div>
                    </template>
                </div>
                <div class="right">
                    <h3>จัดส่งโดย</h3>
                    <div class="data">
                        <template v-if="content.deliveryData && content.deliveryData.ref">
                            {{ content.deliveryData.name }}
                            <br />
                            หมายเลขพัสดุ <span v-html="splitDeliveryTracking(content.deliveryData.ref)"></span>
                        </template>
                        <template v-else>
                            อยู่ระหว่างการจัดเตรียมสินค้า
                        </template>
                    </div>

                    <h3>วิธีชำระเงิน</h3>
                    <div class="data">
                        {{ content.paymentMethod }}
                    </div>
                </div>
            </div>
        </template>

        <order-table :content="content" :mode="mode" class="mt-4"></order-table>

        <div class="ylt-gift mt-2" v-if="content.yltGift.length > 0">
            <div class="item" v-for="(item, index) in content.yltGift" :key="index">
                {{ item.title }}
            </div>
        </div>

        <div class="delivery-remark mt-2" v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
            <div class="wrapper">
                {{ constants.DELIVERY_REMARK }}
            </div>
        </div>

        <div class="remark mt-4">
            <h4>หมายเหตุ:</h4>
            <ul v-if="content.orderType === constants.ORDER_TYPE.SERVICE">
                <li>บริษัทฯ จะออก ใบนัดหมายและติดตั้ง (receipt and installation) เพื่อนำไปแสดงที่ร้านยางที่ได้ระบุไว้ในเอกสารเท่านั้น (ไม่สามารถเปลี่ยน/ย้ายได้)</li>
                <li>ก่อนเข้ารับบริการ หรือ ติดตั้งสินค้า กรุณาโทรนัดหมายล่วงหน้าอย่างน้อย 1 วัน เพื่อจัดเตรียมสินค้า ตามเบอร์โทรศัพท์ ที่ระบุในใบนัดหมายและติดตั้ง (receipt and installation)</li>
                <li>บริษัทฯ ขอสงวนสิทธิ์ไม่รับคืนสินค้าในทุกกรณี</li>
                <li>ใบนัดหมายและติดตั้ง (receipt and installation) มีอายุ 1 เดือน หรือ 30 วัน นับตั้งแต่วันที่ได้ทำการซื้อสินค้าในระบบ บริษัทฯ ขอสงวนสิทธิ์ไม่คืนเงิน ในกรณีที่ลูกค้าไม่เข้ามารับบริการหรือติดตั้งสินค้าภายในระยะเวลาที่กำหนด</li>
                <li>ร้านยาง จะให้บริการตามรายชื่อของผู้ชำระเงินในระบบออนไลน์เท่านั้น กรุณาแสดงเอกสารยืนยันตัวตน หรือ บัตรประจำตัวประชาชน และ ใบนัดหมายและติดตั้ง (receipt and installation) ก่อนรับบริการ (กรณีมอบหมายให้ผู้อื่นกระทำการแทน ต้องมี หนังสือมอบอำนาจ และ สำเนาบัตรประชาชนมาแสดงด้วย)</li>
                <li>ใบนัดหมายและติดตั้ง (receipt and installation) ไม่สามารถ แลก เปลี่ยน หรือ ทอน เป็นเงินสด หรือ ส่วนลดอื่นๆ ได้</li>
                <li>กรณีชำระเงินแบบมัดจำ  กรุณาเข้ารับบริการภายในวันและช่วงเวลาที่เลือกไว้ ค่าสินค้าและบริการส่วนที่เหลือ ชำระที่ร้านในวันติดตั้งโดยสามารถชำระเป็น เงินสด โอนชำระ หรือ บัตรเครดิต ตามที่ร้านกำหนด *กรุณาตรวจสอบเพิ่มเติม หากไม่เข้ารับบริการภายในวันที่กำหนด และไม่ได้แจ้งให้ทราบล่วงหน้า ทางบริษัท ฯ ขอสงวนสิทธิ์ไม่คืนเงินมัดจำ ในทุกกรณี</li>
            </ul>
            <ul v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
                <li>ใบสั่งซื้อจัดส่งสินค้า (Purchase Order) ไม่สามารถ แลก เปลี่ยน หรือ ทอน เป็นเงินสด หรือ ส่วนลดอื่นๆ ได้</li>
                <li>บริษัทฯ จะออก ใบสั่งซื้อจัดส่งสินค้า (Purchase Order) เพื่อเป็นหลักฐาน (ไม่สามารถยกเลิก/เปลี่ยนได้)</li>
                <li>บริษัทฯ ขอสงวนสิทธิ์ไม่รับคืนสินค้าทุกกรณี ยกเว้นทางร้านค้าจัดส่งสินค้าไม่ตรงกับคำสั่งซื้อ</li>
                <li>ราคาสินค้าเป็นราคาสุทธิ รวมภาษีมูลค่าเพิ่มแล้ว</li>
                <li>ใบเสร็จรับเงิน/ใบกำกับภาษี จะแนบไปกับสินค้า</li>
                <li>ลูกค้าต้องตรวจเช็คสินค้าเมื่อท่านได้รับสินค้าทันที และปฏิเสธการรับสินค้าหากพบปัญหา เช่น ผิดรุ่น, ผิดสี, จำนวนไม่ครบ หรือชำรุดเสียหาย เป็นต้น</li>
                <li>กรณีที่พบว่าสินค้ามีปัญหา กรุณาถ่ายรูปและคลิปวีดีโอไว้เป็นหลักฐานประกอบนำไปใช้เป็นข้อพิสูจน์เพื่อประกอบการพิจารณา</li>
                <li>ลูกค้าต้องแจ้งเรื่องมาที่ yellowtire.com ในวันที่ได้รับสินค้า หากแจ้งหลังจากวันดังกล่าวถือว่าลูกค้ายอมรับและได้ตรวจรับสินค้าเป็นที่เรียบร้อยแล้ว</li>
                <li>กรณีมีข้อสงสัยใด ๆ โปรดติดต่อเจ้าหน้าที่ Call Center โทร. 0-2026-7407 ภายในวันและเวลาทำการ 07.00 - 16.00 น. หรือ LINE : yellowtire</li>
            </ul>

            <p>เพิ่มเติมติดต่อ www.Yellowtire.com โทร: +66 (0) 2026 7407</p>
        </div>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import Constants from '@/variables/constants';
import OrderTable from '@/components/order/OrderTable';

export default {
    components: { OrderTable },
    data() {
        return {
            helper: Helper,
            constants: Constants
        }
    },
    props: {
        content:  {
            type: Object,
            required: true,
            default: null
        },
        mode: {
            type: String,
            required: false,
            default: 'general'
        }
    },
    methods: {
        splitDeliveryTracking(text) {
           return Helper.splitDeliveryTracking(text);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.order-print {
    * {
        font-family: 'Tahoma';
        font-size: 11px;
        line-height: 13px;
    }

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .yellow {
            display: flex;
            align-items: center;

            .name {
                font-size: 22px;
                line-height: 24px;
                font-weight: bold;
            }

            address {
                font-size: 14px;
                line-height: 16px;
                margin: 0;
                margin-left: 20px;
            }
        }
    }
    
    .title {
        display: flex;
        justify-content: space-between;

        .data {
            h2 {
                margin: 0;
                margin-right: 10px;
            }

            span {
                display: block;
            }
        }
    }

    .info {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        h3 {
            font-weight: bold;
            margin: 15px 0 10px;
        }

        .right {
            padding-left: 15px;
            text-align: right;
        }
    }

    .ylt-gift {
        .item {
            font-size: 11px;
            font-weight: bold;
        }
    }

    .delivery-remark {
        .wrapper {
            padding: 15px;
            display: inline-block;
            border: 1px dashed #aaa;
            font-size: 8px;
        }
    }

    .remark {
        h4 {
            font-size: 11px;
        }

        ul, p {
            font-size: 8px;
        }
    }
}

.order-detail {
    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media only screen and (max-width: $screenSmall) {
            display: block;
        }

        .yellow {
            display: flex;
            align-items: center;

            @media only screen and (max-width: $screenSmall) {
                display: block;
            }

            .name {
                font-size: 22px;
                line-height: 26px;
                font-weight: bold;

                @media only screen and (max-width: $screenSmall) {
                    margin: 0 auto 15px;
                    text-align: center;
                }
            }

            address {
                margin: 0;
                margin-left: 20px;
                font-size: 13px;
                line-height: 16px;

                @media only screen and (max-width: $screenSmall) {
                    text-align: center;
                }
            }
        }
    }
    
    .title {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: $screenExtraSmall) {
            display: block;
        }

        .data {
            h2 {
                font-size: 15px;
                line-height: 20px;
                margin: 0;
                margin-right: 10px;

                @media only screen and (max-width: $screenSmall) {
                    font-size: 13px;
                    line-height: 15px;
                }
            }

            span {
                font-size: 15px;
                line-height: 20px;
                display: block;

                @media only screen and (max-width: $screenSmall) {
                    font-size: 13px;
                    line-height: 15px;
                }
            }
        }
    }

    .info {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        @media only screen and (max-width: $screenSmall) {
            margin-top: 0;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            display: block;
        }

        h3 {
            font-weight: bold;
            font-size: 15px;
            margin: 15px 0 10px;

            @media only screen and (max-width: $screenSmall) {
                font-size: 14px;
            }
        }

        .left {
            padding-right: 15px;

            @media only screen and (max-width: $screenExtraSmall) {
                padding-right: 0;
            }
        }

        .right {
            padding-left: 15px;
            text-align: right;

            @media only screen and (max-width: $screenExtraSmall) {
                text-align: left;
                padding-left: 0;
            }
        }

        @media only screen and (max-width: $screenExtraSmall) {
            .left {
                padding-right: 0;
            }

            .right {
                text-align: left;
                padding-left: 0;
            }
        }

        .data {
            font-size: 13px;
        }
    }

    .ylt-gift {
        .item {
            font-size: 13px;
            font-weight: bold;
        }
    }

    .delivery-remark {
        .wrapper {
            padding: 15px;
            display: inline-block;
            border: 1px dashed #aaa;
            font-size: 12px;
        }
    }

    .remark {
        h4 {
            font-size: 14px;
        }

        ul, p {
            font-size: 12px;
        }
    }
}
</style>