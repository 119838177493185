<template>
    <div :class="mode === 'print' ? 'order-print' : 'order-table'" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
        <table>
            <thead>
                <tr>
                    <th>รายการสินค้าสั่งซื้อ</th>
                    <th>ราคาเส้นละ</th>
                    <th class="text-end fix-width">จำนวน</th>
                    <th class="text-end fix-width">ราคารวม</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(product, index) in content.products" :key="'product' + index">
                    <td>{{ product.fullName }} (ปี {{ product.year }})</td>
                    <td><span>{{ displayPrice(product.price) }}</span></td>
                    <td class="text-end">{{ product.quantity }}</td>
                    <td class="text-end"><span>{{ displayPrice(product.totalPrice) }}</span></td>
                </tr>
                <tr v-if="content.deliveryData">
                    <td>ค่าจัดส่ง {{ content.deliveryData.name }}</td>
                    <td></td>
                    <td class="text-end"></td>
                    <td class="text-end">
                        <span v-if="content.deliveryData.price">{{ displayPrice(content.deliveryData.price) }}</span>
                        <span v-if="!content.deliveryData.price" class="no-price">ฟรี</span>
                    </td>
                </tr>
                <tr v-for="(service, index) in content.services" :key="'service' + index">
                    <td>{{ service.title }}</td>
                    <td></td>
                    <td class="text-end"></td>
                    <td class="text-end">
                        <span v-if="service.price">{{ displayPrice(service.price) }}</span>
                        <span v-if="!service.price" class="no-price">ฟรี</span>
                    </td>
                </tr>
                <tr v-for="(benefit, index) in content.benefits.filter(x => x.type !== constants.BENEFIT_TYPE.WARRANTY)" :key="'benefit' + index">
                    <td>{{ benefit.title }} {{ benefit.remark }}</td>
                    <td></td>
                    <td class="text-end"></td>
                    <td class="text-end">
                        <span class="no-price">ฟรี</span>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td :rowspan="content.isPrepaid ? 6 : 4" class="warranty">
                        <strong v-if="content.benefits.filter(x => x.type === constants.BENEFIT_TYPE.WARRANTY).length > 0">การรับประกัน</strong>
                        <div class="text" v-for="(benefit, index) in content.benefits.filter(x => x.type === constants.BENEFIT_TYPE.WARRANTY)" :key="index">
                            {{ benefit.title }} {{ benefit.remark }}
                        </div>
                    </td>
                    <td></td>
                    <td v-if="content.discountPrice" class="text-end">ส่วนลด</td>
                    <td v-if="content.discountPrice" class="text-end">- <span>{{ displayPrice(content.discountPrice) }}</span></td>
                </tr>
                <tr v-if="content.isPrepaid">
                    <td></td>
                    <td class="text-end">มัดจำแล้ว</td>
                    <td class="text-end"><span>{{ displayPrice(content.prepaidPrice) }}</span></td>
                </tr>
                <tr v-if="content.isPrepaid">
                    <td></td>
                    <td class="text-end">ยอดคงเหลือที่ต้องชำระ</td>
                    <td class="text-end"><span>{{ displayPrice(content.remainPrice) }}</span></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="text-end">รวมเงิน</td>
                    <td class="text-end"><span>{{ displayPrice(content.noVatPrice) }}</span></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="text-end">ภาษีมูลค่าเพิ่ม 7%</td>
                    <td class="text-end"><span>{{ displayPrice(content.includeVatPrice) }}</span></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="text-end">จำนวนเงินทั้งสิ้น</td>
                    <td class="text-end total"><span>{{ displayPrice(content.orderTotal) }}</span></td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import MixinDragScroll from '@/mixins/dragScroll';
import Helper from '@/services/helper';
import Constants from '@/variables/constants';

export default {
    mixins: [ MixinDragScroll ],
    data() {
        return {
            constants: Constants
        }
    },
    props: {
        content:  {
            type: Object,
            required: true
        },
        mode: {
            type: String,
            required: false,
            default: 'general'
        }
    },
    methods: {
        displayPrice(price) {
            price = price ? price.toString().replace(',', '') : '';

            if (price && !isNaN(price)) {
                return Helper.getCommaNumber(parseFloat(price).toFixed(2));
            }

            return price;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.order-table {
    overflow: hidden;

    table {
        width: 100%;
        min-width: 500px;
        font-size: 13px;

        th, td {
            padding: 7px 10px;
        }

        thead {
            th {
                border-bottom: 1px solid #999;
                font-size: 15px;
                color: #555;
                white-space: nowrap;

                @media only screen and (max-width: $screenExtraSmall) {
                    font-size: 14px;
                }
            }

            th.fix-width {
                width: 180px;
            }
        }

        tbody {
            td {
                border-bottom: 1px solid #ddd;
            }
        }

        tfoot {
            tr:first-child {
                td {
                    padding-top: 10px;
                }
            }

            td.warranty {
                white-space: normal;
                vertical-align: top;
                padding: 20px;

                .text {
                    font-size: 12px;
                }
            }

            td {
                padding: 5px 0;
                white-space: nowrap;

                &.total {
                    font-weight: bold;
                    font-size: 24px;

                    @media only screen and (max-width: $screenLarge) {
                        font-size: 16px;
                    }

                    @media only screen and (max-width: $screenExtraSmall) {
                        font-size: 15px;
                    }
                }
            }
        }

        span {
            &.no-price {
                &:before {
                    content: "";
                }
            }

            &:before {
                content: "฿";
                margin-right: 5px;
            }
        }
    }
}

.order-print {
    table {
        width: 100%;

        * {
            font-family: 'Tahoma';
            font-size: 11px;
            line-height: 13px;
        }

        th, td {
            padding: 7px;
        }

        thead {
            th {
                border-bottom: 1px solid #999;
                color: #555;
            }
        }

        tbody {
            td {
                border-bottom: 1px solid #999;
            }
        }

        tfoot {
            tr:first-child {
                td {
                    padding-top: 7px;
                }
            }

            td.warranty {
                white-space: normal;
                width: 450px;
                vertical-align: top;
                padding: 20px;

                strong {
                    font-size: 10px;
                }

                .text {
                    font-size: 10px;
                }
            }

            td {
                padding: 5px 0;

                &.total {
                    font-weight: bold;
                }
            }
        }

        span {
            &.no-price {
                &:before {
                    content: "";
                }
            }

            &:before {
                content: "฿";
                margin-right: 5px;
            }
        }
    }
}
</style>