<template>
    <section class="paper" v-if="content">
        <div class="job-summary">
            <order-job-summary :content="content" mode="print"></order-job-summary>
        </div>

        <div class="title">
            <h1 v-if="content.orderType === constants.ORDER_TYPE.SERVICE">ใบรับเงินทดรอง</h1>
            <h1 v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">ใบสั่งซื้อสินค้า</h1>
        </div>

        <order-detail :content="content" mode="print"></order-detail>
    </section>
</template>

<script>
import OrderJobSummary from '@/components/order/JobSummary';
import OrderDetail from '@/components/order/OrderDetail';
import Constants from '@/variables/constants';

export default {
	components: {
		OrderDetail,
        OrderJobSummary
	},
    data() {
        return {
            constants: Constants
        }
    },
    props: {
        content: Object
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.paper {
    margin: 0 auto;
    overflow: hidden;
    background: #fff;
    width: 21cm;
    height: 29.7cm;
    padding: 1cm;
    
    * {
        font-family: 'Tahoma';
        font-size: 11px;
        line-height: 13px;
    }

    .job-summary {
		margin-bottom: 20px;
	}

    .title {
		text-align: center;
		margin-bottom: 20px;

		h1 {
			font-size: 20px;
            font-weight: bold;
			margin: 0;
		}
    }
}
</style>