<template>
	<div class="paper">
        <div class="loading" v-if="!isContentReady">
            <loader-content :isDisplay="true"></loader-content>
        </div>

        <template v-if="isContentReady">
            <gadget-step-purchase :step="getStepPurchase()"></gadget-step-purchase>

            <div class="job-summary">
                <order-job-summary :content="orderDetail"></order-job-summary>
            </div>

            <div class="title">
				<h1 v-if="orderDetail.orderType === constants.ORDER_TYPE.SERVICE">ใบรับเงินทดรอง</h1>
				<h1 v-if="orderDetail.orderType === constants.ORDER_TYPE.DELIVERY">ใบสั่งซื้อสินค้า</h1>
                <div class="operation">
                    <b-icon icon="printer" class="icon" @click="print()"></b-icon>
                    <b-icon icon="download" class="icon" @click="downloadPDF('AdvanceReceipt')"></b-icon>
                </div>
            </div>

            <order-detail :content="orderDetail"></order-detail>
        </template>

        <div class="print-panel">
            <div ref="printElement">
                <order-print :content="orderDetail"></order-print>
            </div>
        </div>

        <img id="paperPrint" :src="printImage" v-if="printImage" class="print-image" />
    </div>
</template>

<script>
import Constants from '@/variables/constants';
import OrderJobSummary from '@/components/order/JobSummary';
import OrderDetail from '@/components/order/OrderDetail';
import OrderPrint from '@/components/order/OrderPrint';
import GadgetStepPurchase from '@/components/gadget/StepPurchase';
import MixinPrintPaper from '@/components/seller/mixins/printPaper';

export default {
	mixins: [ MixinPrintPaper ],
	components: {
		OrderJobSummary,
		OrderDetail,
		OrderPrint,
        GadgetStepPurchase
	},
	data() {
		return {
			constants: Constants,
			isContentReady: false
		};
	},
    props: {
        orderDetail: Object
    },
    methods: {
        getStepPurchase() {
            if (this.orderDetail.statusCode === Constants.ORDER_STATUS.COMPLETED_SERVICE
                || this.orderDetail.statusCode === Constants.ORDER_STATUS.COMPLETED_DELIVERY) {
                return 7;
            }
            
            return 6;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/order/style/PaperPrint.scss';
</style>